import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from '../src/Login-Signup/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthProvider>
            <App />
      </AuthProvider>
);
