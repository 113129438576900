import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import image from './E-Plato_logo.png';
import userimg from '../assets/user_32.png';
import SocrateLogo from '../assets/socrate-logo.png';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import './Topbar.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import admin from '../assets/admin.png';
import { MdOutlineEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoDocumentsOutline } from "react-icons/io5";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import downloadimage from '../assets/download-icon.svg'
import {environment} from '../environments/environment';
import  FetchInterceptor  from '../secure/AuthInterceptor';
import axiosHttp from "../secure/axiosHttp"; 
import InputGroup from 'react-bootstrap/InputGroup';
import { PiImageSquare } from "react-icons/pi";

const Topbar = ({page,fetchDocuments}) => { 
  const [show, setShow] = useState(false);
  const [files,setFiles] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [folderList,setFolderList]=useState([]);
  const handleClose = () => {setFiles([]);setShow(false)};
  const handleShow = () => setShow(true);
    const navigate = useNavigate();
    function signout(){
      const auth = getAuth();
      signOut(auth).then(() => {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }).catch((error) => {
        // An error happened.
      });
    }
    function onFileChange(event) {
      const filesArr=Array.prototype.slice.call(event.target.files);
      setFiles(filesArr);
     };

     async function fetchFolerList(){
      const url=process.env.REACT_APP_SERVER_URL + 'getFolders'
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      }
    FetchInterceptor(navigate);
    await fetch(url,requestOptions)
    .then(response=>response.json())
    .then(folders=>{setFolderList(folders)}) 
    .catch(error=>console.log(error))
  } 

     async function uploadFiles(){
      const data = new FormData();
      if(files.length===0){
        alert("Please select file(s)")
        return
      }
      files.forEach(file => {
        data.append('files',file);
      })
      data.append('type','multi');
      if(selectValue=='')
      {
        alert('Please a select folder.')
        return
      }
      data.append('folder',selectValue);
      data.append('company','');
      
      const url=process.env.REACT_APP_SERVER_URL + 'uploadFiles';
      const response = await axiosHttp.post(url,data); 
      handleClose();
      if(response.data.numFilesUploaded>0 && response.status===200){
        alert(response.data.numFilesUploaded + " document(s) uploaded");
        setFiles([])
        fetchDocuments();
      }
      
     }

    const renderPage=()=>{
      if(page=='LoginSignup'){
        return(<></>)
      } 
      else if(page=='MyDocuments'){
        return(
          <Col xs={7} md={2} className='navbar justify-content-evenly'>
            <Button className="user-button" onClick={e=>{setSelectValue('');fetchFolerList();handleShow();}}>Upload</Button>
            <Navbar className='source-nav'>
              <Nav className="dropdown-category-dash flex-grow-1 justify-content-center">
                <NavDropdown title={<Image src={userimg} width="35"/>} className='user-img'>
                  <NavDropdown.Item to="/home" as={Link}><IoDocumentsOutline/>Home</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>navigate(-1)}><IoDocumentsOutline/>Search</NavDropdown.Item>
                  <NavDropdown.Item onClick={signout}><IoMdClose/>logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar> 
          </Col>
        )
      } 
      else if(page=='ImagesSetting'){
        return(
          <Col xs={7} md={2} className='navbar justify-content-evenly'>
            <Button className="user-button" onClick={handleShow}>Upload</Button>
            <Navbar className='source-nav'>
              <Nav className="dropdown-category-dash flex-grow-1 justify-content-center">
                <NavDropdown title={<Image src={userimg} width="35"/>} className='user-img'>
                  <NavDropdown.Item to="/home" as={Link}><IoDocumentsOutline/>Home</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>navigate(-1)}><IoDocumentsOutline/>Search</NavDropdown.Item>
                  <NavDropdown.Item to="/mydocuments" as={Link}><IoDocumentsOutline/>My Documents</NavDropdown.Item>
                  <NavDropdown.Item onClick={signout}><IoMdClose/>logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar> 
          </Col>
        )
      }
      else if(page=='Home'){
        return(
          <Col xs={7} md={2} className='navbar justify-content-end'>
            <div className='admin-icon'><Image src={admin} className='image-admin'/></div>
            <Navbar className='admin-drop'><Nav>
                    <NavDropdown title="Admin" className="basic-nav-dropdown" renderMenuOnMount={true}>
                      <NavDropdown.Item to="/mydocuments" as={Link}><IoDocumentsOutline/>My Documents</NavDropdown.Item>
                      <NavDropdown.Item><MdOutlineEdit/>Change Password</NavDropdown.Item>
                      <NavDropdown.Item onClick={signout}><IoMdClose/>Logout</NavDropdown.Item>
                    </NavDropdown>
                  </Nav></Navbar>
            </Col>
        )
      }else if(page=='Search'){
        return(
          <Col xs={7} md={2} className='navbar justify-content-evenly'>
          <Button className="mydoc-button" to="/mydocuments" as={Link}>My Documents</Button>
          <Navbar className='source-nav'>
              <Nav className="dropdown-category-dash flex-grow-1 justify-content-center">
                <NavDropdown title={<Image src={userimg} width="35"/>} className='user-img'>
                  <NavDropdown.Item to="/imagessetting" as={Link}><PiImageSquare />Images</NavDropdown.Item>
                  <NavDropdown.Item to="/home" as={Link}><IoDocumentsOutline/>Home</NavDropdown.Item>
                  <NavDropdown.Item onClick={signout}><IoMdClose/>logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar> 
            
        </Col>
        )
      }
      else{
        return(
          <Col xs={7} md={2} className='navbar justify-content-evenly'>
          <Navbar className='source-nav'>
              <Nav className="dropdown-category-dash flex-grow-1 justify-content-center">
                <NavDropdown title={<Image src={userimg} width="35"/>} className='user-img'>    
                  <NavDropdown.Item onClick={signout}>logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar> 
        </Col>
        )
      }
    }
  return(
        <>
          <Container fluid>
            <Row className='top-bar'>
              <Col xs={5} md={10} className='navbar justify-content-start'>
              {
                page=='LoginSignup' || page=='Home'  ? <Image src={image} width="175"  /> : <Image src={SocrateLogo} width="150" />
              }
              </Col>
              {
                renderPage()
              }
            </Row>
          </Container>
          <Modal show={show} onHide={handleClose}> 
            <Modal.Body>
              <Form>
                <Form.Group className="text-center" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="file"
                  onChange={onFileChange}
                  multiple
                  />
                  
                </Form.Group>
                {
                files.length>1?
                <span><b>Uploaded File(s):</b> {files.map(file=>file.name).join(',')}</span> :<></>}
                <Form.Group controlId="formBasicSelect">
                  <Form.Label><b>Select a folder to upload</b></Form.Label>
                  <Form.Select value={selectValue} onChange={(e) => setSelectValue(e.target.value)} aria-label="Choose Folder to upload">
                  <option value="">Select a Folder</option>
                    {folderList.map((folder,index)=><option key={index}>{folder.folder_name}</option>)}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={uploadFiles}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
       </>
        
      
      )
}

export default Topbar;        