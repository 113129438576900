import axios from "axios";
import {environment} from '../environments/environment';
import { auth } from '../environments/firebase';

const axiosHttp = axios.create({
  baseURL: environment.SERVER_URL,
});

export const AxiosInterceptors = (navigate) => {
axiosHttp.interceptors.request.use(
  (config) => {
    const token =  localStorage.getItem('token')
    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `${token}` }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {status} = error.response;
    switch (status) {
      case 400:
        console.log(error.response);
        break;
      case 401:
        try {
          const originalRequest = error.config;
          const user = auth.currentUser;
          console.log(user);
          if (user) {
            const newToken = await user.getIdToken(true);
            localStorage.setItem('token',newToken)
            originalRequest.headers.Authorization = `${newToken}`;
            return axios(originalRequest);
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log(error);
          } else {
          auth.signOut();  
          localStorage.removeItem('token');
          navigate('/login', {replace: true, state: { sessionExpired: true } });
          }
        }
        break;
      case 404:
        console.log(error.response.status+' : '+error.response.statusText);
        break;
      case 415:
        console.log(error.response.status+' : '+error.response.statusText);
        break;
      case 500:
        console.log("server error");
        break;
      default:
        console.log("an unknown error occurred");
        break;
    }
    return Promise.reject(error);
  }
);
}
export default axiosHttp;