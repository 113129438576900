import { auth } from '../environments/firebase';

const refreshToken = async () => {
  const user = auth.currentUser;
  if (user) {
    const newToken= await user.getIdToken(true); // Force refresh of token
    localStorage.setItem('token',newToken);
    return newToken;
  }
};

const FetchInterceptor = async (url, options = {},navigate) => {
  try {
    const token = localStorage.getItem('token');
    const headers = {
      ...options.headers,
      Authorization: token
    };

    const response = await fetch(url, {
      ...options,
      headers,
    });
    switch (response.status) {
      case 400:
          console.log(response);
          break;
      case 401:
        try {
          const newToken = await refreshToken();
          const retryResponse = await fetch(url, {
          ...options,
          headers: {
            ...headers,
            Authorization: newToken,
          },
        });
      return retryResponse;
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log(error);
          } else {
          auth.signOut();  
          localStorage.removeItem('token');
          navigate('/login', {replace: true, state: { sessionExpired: true } });
          }
        }
          break;
      case 404:
          console.log(response.status+' : '+response.statusText);
          break;
      case 415:
          console.log(response.status+' : '+response.statusText);
          break;
      case 500:
          console.log("server error");
          break;
      default:
          console.log("an unknown error occurred");
          break;
      }
    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export default FetchInterceptor;