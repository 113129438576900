import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import "./SearchResults.css";
import { FaFile, FaFilePdf} from 'react-icons/fa';
import { SiGoogledocs } from "react-icons/si";
import { TbFileTypeTxt } from "react-icons/tb";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { BsHandThumbsUp } from "react-icons/bs";
import { BsHandThumbsDown } from "react-icons/bs";
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { BsFillHandThumbsDownFill } from "react-icons/bs";
import axiosHttp,{AxiosInterceptors} from "../secure/axiosHttp"; 
import { useNavigate } from 'react-router-dom';

const FileIcon = ({ extension }) => {
  const iconMap = {
    pdf: <FaFilePdf />,
    mp4: <MdOutlineOndemandVideo />,
    txt: <TbFileTypeTxt />,
    doc: <SiGoogledocs />,
    docx: <SiGoogledocs />,
    xlsx: <BsFillFileEarmarkExcelFill />,
    default: <FaFile />,
  };
  const icon = iconMap[extension.toLowerCase()] || iconMap.default;
  return <div className="file-icon">{icon}</div>;
};

 const SearchResults = ({results,appliedFilters,currentPage,text}) =>{
  const navigate = useNavigate();

  function updateRating(e,numid,spid,pdfname,para,question){
    let rating;
      if(spid==='1'){
        document.getElementById(numid+'1').style.display='none';
        document.getElementById(numid+'2').style.display='inline-block';
        document.getElementById(numid+'4').style.display='none';
        document.getElementById(numid+'3').style.display='inline-block';
        rating='Good';
        callRatingApi(rating,pdfname,para,question);
      }else if(spid==='2'){
        document.getElementById(numid+'1').style.display='inline-block';
        document.getElementById(numid+'2').style.display='none';
        document.getElementById(numid+'4').style.display='none';
        document.getElementById(numid+'3').style.display='inline-block';
      }else if(spid==='3'){
        document.getElementById(numid+'2').style.display='none';
        document.getElementById(numid+'1').style.display='inline-block'
        document.getElementById(numid+'3').style.display='none';
        document.getElementById(numid+'4').style.display='inline-block'
        rating='Bad';
        callRatingApi(rating,pdfname,para,question);
      }else if(spid==='4'){
        document.getElementById(numid+'2').style.display='none';
        document.getElementById(numid+'1').style.display='inline-block'
        document.getElementById(numid+'3').style.display='inline-block';
        document.getElementById(numid+'4').style.display='none'
      }
  }

  async function callRatingApi(rating,pdfname,para,question){
    const url=process.env.REACT_APP_SERVER_URL+'submitRating'
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        "question": question,
        "company":appliedFilters,
        "answers": [
            {
                "paragraph": para,
                "pdfname": pdfname,
                "ratings": rating
            },
        ],
    }
  };

 await axiosHttp.post(url,requestOptions)
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    })
    .then(data=>{console.log(data)})
    .catch(error => {console.error('There was a problem with the fetch operation:', error)});
}

useEffect(() => {
  AxiosInterceptors(navigate);
}, []);

    return(
      <>
       {
            results.map((data,index)=>{
              return(<Col id={index} >
                <Card className="border-0">
                  <Card.Body className="search-card-body">
                    <Card.Text className="search-title">{
                      <>
                      <FileIcon extension={data.pdfName.split('.').pop().toLowerCase()} />
                      <Link to={'/viewpdf?data='+encodeURIComponent(JSON.stringify({pdfName:data.pdfName,page_number:data.page_number,file_path:data.file_path}))} target="_blank" className="tag-a">{data.pdfName}</Link>
                      </>
                    }
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="cards-footer-search-result">
                  {data.answer}
                    <Card.Text key={currentPage+'_'+index+'_'+appliedFilters+'_'+data.pdfName} id={currentPage+'_'+index+'_'+appliedFilters+'_'+data.pdfName}>
                      <span className="like" id={index+'1'}  numid={index} spid='1' title='Like' onClick={e=>updateRating(e,index,'1',data.pdfName,data.answer,text)}><BsHandThumbsUp /></span>
                      <span className="like hide"  id={index+'2'} numid={index} spid='2' title='Like' onClick={e=>updateRating(e,index,'2',data.pdfName,data.answer,text)} ><BsFillHandThumbsUpFill /></span>
                      <span className="dislike" id={index+'3'} numid={index} sp-d='3' title='Dislike' onClick={e=>updateRating(e,index,'3',data.pdfName,data.answer,text)}><BsHandThumbsDown /></span>
                      <span className="dislike hide" id={index+'4'} numid={index} spid='4' title='Dislike' onClick={e=>updateRating(e,index,'4',data.pdfName,data.answer,text)} ><BsFillHandThumbsDownFill /></span>
                    </Card.Text> 
                  </Card.Footer>
                </Card>
              </Col>)
              
              
          })}
      </>
    )
}

export default SearchResults;