import React, { useState,useEffect,useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Form } from "react-bootstrap";
import Topbar from '../Topbar/Topbar';
import './LoginSignup.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../environments/firebase';
import { initializeApp } from "firebase/app";
import axiosHttp from "../secure/axiosHttp"; 

function Signup() {
  //Singup handling
  const [Email, setEmail]=useState("");
  const [Password, setPassword]=useState("");
  const [companyName,setCompanyName]=useState([]);
  const navigate = useNavigate();
  const selectRef = useRef(null)
  
  //Handle Signup form submission
  const handleSignup = async () =>{
    const company=selectRef.current.value;
    console.log(selectRef.current.value);
    //mapUser(company);
    createUserWithEmailAndPassword(auth, Email, Password)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        localStorage.setItem('token', user.accessToken);
        alert('User signedup successfully!');
        mapUser(company);
        
      })
      .catch((error) => {
        const errorCode = error.code;
        if(errorCode=='auth/missing-email'){
          alert('Please enter email address.');
        }
        else if(errorCode=='auth/email-already-in-use'){
          alert('This email is already in use.');
        }
        else if(errorCode=='auth/invalid-email'){
          alert('Invalid email address.');
        }
        else if(errorCode=='auth/missing-password'){
          alert('Please enter valid password.')
        }
        else if(errorCode=='auth/weak-password'){
          alert('Weak password. Please try with strong password.')
        }
      });
       
  }
  async function mapUser(companyId){
      const url=process.env.REACT_APP_SERVER_URL + 'mapUserToCompany';
    const response = await axiosHttp.post(url,{companyId});
    console.log(response);
    if(response.data.message=="Success"){
      navigate('/');
    }
    else{
      alert('try again!')
    }
  }
  async function getCompanies(){
      const url=process.env.REACT_APP_SERVER_URL + 'getCompanyNames';
      const response =  await axiosHttp.get(url);
      setCompanyName(response.data);
  }
  useEffect(() => {
    getCompanies();
  }, []);

  // Signup Form
  return (
          <>
            <Topbar page="LoginSignup"/>
            <Container >
              <Row className="wrapper-row">
                  <Col xs={12} md={4} className='wrapper-col'>
                  <Form className='login-form'>
                          <Form.Group className="mb-3" controlId="formBasicEmail" >
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" placeholder="Enter email" value={Email} onChange={(event)=>{setEmail(event.target.value)}}/>
                          </Form.Group>
              
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Password</Form.Label>
                              <Form.Control type="password" placeholder="Password" value={Password} onChange={(event)=>setPassword(event.target.value)}/>
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          </Form.Group>

                          <Form.Group className="mb-3">
                              <Form.Label>Company</Form.Label>
                              <Form.Select ref={selectRef}>
                                {
                                  companyName.map((name,index) =><option key={index} value={name.id}>{name.company_name}</option>)
                                }
                              </Form.Select>
                          </Form.Group>
                          <Button className='login-button' type="button"  onClick={handleSignup}>Signup</Button>
                      </Form>
                    </Col>
              </Row>
            </Container>
          </>
   
  );
}

export default Signup;