import {Container, Card, Row, Col, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar/Topbar";
import Searchbar from "../Searchbar/Searchbar";
import {useState, useEffect, useRef} from 'react';
import {FaSearch} from "react-icons/fa";
import {environment} from '../environments/environment';
import { Link, useLocation } from "react-router-dom";
import  FetchInterceptor  from '../secure/AuthInterceptor';
import LoadingBar from 'react-top-loading-bar';
import AppPagination from '../Pagination/Paginate'
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Search = () => {
  const navigate = useNavigate();
  const ref = useRef(null)
  const {state} = useLocation();
  const [data, setData] = useState([]);
  const [isFilterApplied,setIsFilterApplied]=useState(false);
  const [genres, setGenres] = useState("");
  const [source,setSource]=useState("");
  const [text,setText] = useState("");
  const [oldText,setOldText] = useState(state);
  const [queryText,setQueryText] = useState(state);
  const [folderList,setFolderList]=useState([]);
  const [spin,setSpin]=useState(false);
  const [itemId, setItemId] = useState("All");
  let [buttonId, setButtonId] = useState("Text");
  let [folderName,setFolderName]=useState('');
  let [appliedFilters,setAppliedFilters]=useState("");
  let controller = new AbortController();
  let signal = controller.signal;

  function setOldTextValue(){
    setOldText(text)
  }
  
  const setFilterTextVal = (text) =>{
    setText(text);
  }

  async function filterByText() {
    setSource('');
    setSpin(true);
    ref.current.continuousStart();

    let url = process.env.REACT_APP_SERVER_URL + 'predictChain';
    if (buttonId === 'Images') {
        url = process.env.REACT_APP_SERVER_URL + 'predictImages';
    }

    const query = text || state || '';
    setQueryText(query);
    setOldText(query);

    const requestOptions = {
        signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            question: query,
            company: appliedFilters || null,
        }),
    };

    try {
        const response = await FetchInterceptor(url, requestOptions, navigate);

        // Prepare to read the streamed response
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let finalResults = [];
        let streamedText = '';

        // Process the streamed data
        while (true) {
            const { done, value } = await reader.read();
            if (done) break; // End of stream

            let chunk = decoder.decode(value, { stream: true });

            // Strip the "data: " prefix from the chunk if it exists
            if (chunk.startsWith('data: ')) {
                chunk = chunk.slice(6); // Remove the "data: " prefix
            }

            try {
                // Try to parse the cleaned chunk as JSON
                const jsonChunk = JSON.parse(chunk);

                // If the chunk contains a message, update the UI with the progress message
                if (jsonChunk.message) {
                    streamedText = jsonChunk.message;
                    setGenres(streamedText); // Update UI with the streaming message
                }

                // Update UI with the final or intermediate results
                setGenres(streamedText);
                setSpin(false); // Stop the spinner after the stream ends or on every update

            } catch (e) {
                // If parsing fails, log the error (this might happen if the chunk is not yet valid JSON)
                console.error('Error parsing chunk:', e);
            }
        }

        // Once the stream is done, update the UI with final results
        setSpin(false);
        ref.current.complete(); // Complete the progress bar or spinner

        // Now call /predictChainResult to get the final results
        const finalResultsResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}predictChainResult`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const finalResultsData = await finalResultsResponse.json();

        if (finalResultsData.error) {
            setGenres(finalResultsData.error); // Show the error message
        } else {
            setData(finalResultsData.final_results); // Set the final results to the state
            fetchGenresponse(finalResultsData.final_results, folderName); // Fetch additional response if needed
        }
    } catch (error) {
        console.error('Error in filterByText', error);
        setGenres('An error occurred while fetching the response');
        setSpin(false);
        ref.current.complete();
    } finally {
        setSpin(false); // Ensure the spinner is stopped in case of an error
    }
}

  const fetchGenresponse  = async (resdata,folderName) => {
    try {
    setGenres("")
    const query=text || state || '';
    const filteredSearchResults=resdata;
    const newarr=filteredSearchResults.slice(0, 2).map(result => {
      return(<Link to={'/viewpdf?data='+encodeURI(JSON.stringify({pdfName:result.pdfName,page_number:result.page_number}))} target="_blank" className='source-link'>{result.pdfName}</Link>
      )
    })
    setSource(newarr.reduce((prev, curr) => [ prev, ' , ', curr ]));
    const composedQuery = filteredSearchResults.slice(0, 5).map((result, index) =>
      `Document ${index + 1}: ${result.pdfName}\nContext ${index + 1}: ${result.answer}`
    ).join("\n\n") + "\n\n Question: \n" + query + "\n\n Answer: ";
    const genresurl = process.env.REACT_APP_SERVER_URL +'generate';
    const requestOptions = {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query:composedQuery,
        demo: process.env.REACT_APP_DEMO
      })
    } 
    const response = await FetchInterceptor(genresurl,requestOptions,navigate);
    setSpin(false);
    let streamedtext="";
    const reader = response.body.getReader();
    const chunks = [];
      const decoder = new TextDecoder('utf-8');
      let done, value;
      while (!done) {
        ({ value, done } = await reader.read());
        value = decoder.decode(value);
        if (done) {
          return chunks;
        }
        chunks.push(value);
        streamedtext=streamedtext+value
        setGenres(streamedtext);
      }
      
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log(error);
      } else {
        setGenres("Unable to fetch response. An error occured!");
        setSpin(false);
      }
    }
  };

  async function fetchFolderList(){
      const url=process.env.REACT_APP_SERVER_URL + 'getFolders'
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'text/event-stream'
        },
      }
    await FetchInterceptor(url,requestOptions,navigate)
    .then(response=>response.json())
    .then(folders=>setFolderList(folders)) 
    .catch(error=>console.log(error))
  }
  useEffect(() => {
    if(isFilterApplied===false){
      filterByText();
      return () => {
        controller.abort();
      };
    }else if(isFilterApplied===true){
        setSource('');
        if (folderName==="All") {
          appliedFilters="";
          setIsFilterApplied(false);
          filterByText();
        } else {
          setIsFilterApplied(true);
          filterByText();
        }
        return () => {
          controller.abort();
        };
    }
  }, [oldText,folderName]);

  useEffect(()=>{
    fetchFolderList();
  },[])


  return(
    <>
    <LoadingBar color='rgba(254, 70, 123, 1)' ref={ref} />
    <Topbar page="Search"/>
    <Searchbar setFilterTextVal={setFilterTextVal}  textval={state} filterByText={filterByText} setOldTextValue={setOldTextValue}/>
    <Container className='article-container' fluid>
    <Row >
      <Col xs={12} md={12} className="d-flex justify-content-center align-items-center " > 
        <span title="Text" id='Text' onClick={()=>{setData([]);buttonId='Text';setButtonId('Text');filterByText();}} className={buttonId=="Text"?'active-button':'text-button'}>Text</span>
        <span title="Images" id='Images' onClick={()=>{setData([]);buttonId='Images';setButtonId('Images');filterByText();}} className={buttonId=="Images"?'active-button':'text-button'}>Images</span >
      </Col>
    </Row>
    <Row className="category-bar" >
              <Col xs={12} md={12} > 
                <Navbar expand="sm">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="dropdown-category flex-grow-1 justify-content-center align-items-center mr-0">
                    <div title="All" id='All'  onClick={()=>{setItemId("All");setAppliedFilters("");setFolderName("All")}} className={itemId=="All"?'active-filter':'menu-all'}>All</div>
                    { folderList.length>0?<>
                      {
                        folderList.slice(0,5).map(flist=><div title={flist} id={flist} onClick={()=>{setItemId(flist);setAppliedFilters(flist);setFolderName(flist)}} className={itemId==flist?'active-filter':'menu-all'}>{flist}</div>)
                      }
                      {
                          folderList.length>5?
                            <NavDropdown title="More"  renderMenuOnMount={true}>
                                  {
                                    folderList.slice(5).map(flist=>(<NavDropdown.Item id={flist} onClick={()=>{setItemId(flist);setAppliedFilters(flist);setFolderName(flist)}} className={itemId==flist?'active':''}>{flist}</NavDropdown.Item>))
                                  }
                            </NavDropdown>
                            :<span></span>
                          }</>
                          :<></>  
                          }
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
              <Col xs={12} md={2} className='d-flex justify-content-end align-items-center'>{isFilterApplied==true? <span><label className='applied-filters-label'>Applied Filters :</label> <span className='applied-filters'>{appliedFilters}</span></span> :<></> }</Col>
            </Row>
            {
            buttonId==='Text'?
            <Row>
              <Col xs={12} md={12}>
                    <Card className="border-0">
                      <Card.Header className='search-header'>
                        Socrate says:
                        </Card.Header>
                        <Card.Footer className='search-footer'>
                        <FaSearch/>
                        <Card.Text className='search-text'>
                          {spin===true ? <div id="spinner" class="loader"></div>:<><ReactMarkdown remarkPlugins={[remarkGfm]}>{genres}</ReactMarkdown></>}
                        </Card.Text>
                        <Card.Text className='search-text'>
                        <b>Source: </b>{source}
                        </Card.Text>
                        </Card.Footer>
                    </Card>
              </Col >  
            </Row>:<></>     
          }
      
        {
          data.length>0?
          <AppPagination data={data} appliedFilters={appliedFilters} text={queryText}  buttonId={buttonId}/>
          :<></>
        }
    </Container>
</>
  )
  };

export default Search;

