import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./Topbar/Topbar.css";
import "./Searchbar/Searchbar.css";
import "./Search/Search.css";
import './index.css';
import Login from './Login-Signup/Login';
import Signup from './Login-Signup/Signup';
import Search from './Search/Search';
import ViewPDF from './ViewPDF/ViewPDF';
import ImagesSetting from './Search/ImagesSetting';
import Home from './Home/Home';
import  MyDocuments from './MyDocuments/MyDocuments';
import PrivateRoute from '../src/Login-Signup/PrivateRoute';
import { useAuth } from '../src/Login-Signup/AuthContext';

function App() {
  const { currentUser } = useAuth(); 
  return (
    <BrowserRouter>
      <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/home" element={<PrivateRoute user={currentUser}><Home/></PrivateRoute>} />
            <Route path="/signup" element={<Signup/>} />
            <Route path="/search" element={<PrivateRoute user={currentUser}><Search/></PrivateRoute>} />
            <Route path="/imagessetting" element={<PrivateRoute user={currentUser}><ImagesSetting/></PrivateRoute>} />
            <Route path="/viewpdf" element={<PrivateRoute user={currentUser}><ViewPDF/></PrivateRoute>} />
            <Route path="/mydocuments" element={<PrivateRoute user={currentUser}><MyDocuments/></PrivateRoute>} />
      </Routes>
    </BrowserRouter>       
  );
}

export default App;