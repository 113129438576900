import React, { useState,useEffect } from 'react';
import { Col, Button, Row, Container, Form } from "react-bootstrap";
import './LoginSignup.css'
import { useNavigate,useSearchParams,useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import Topbar from '../Topbar/Topbar';
import { auth } from '../environments/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";

const Login=()=>{
    //get token param
    const [searchParams, setSearchParams] = useSearchParams();
    const {state} = useLocation();
    useEffect(() => {
        const token=searchParams.get("token");
        if(token!=null){
            localStorage.setItem('token', token);
            navigate('/home');
        }
    }, []);
    //Login handling
    const [LoginEmail, setLoginEmail]=useState("");
    const [LoginPassword, setLoginPassword]=useState("");
    const navigate = useNavigate()

    //Handle Login form submission
    async function handleLoginSubmit (){
        try{
        const result = await signInWithEmailAndPassword(auth, LoginEmail, LoginPassword)
        const idToken = await result.user?.getIdToken();
        localStorage.setItem('token', idToken);
        localStorage.setItem('isAdmin', true);
        navigate('/home');
        }
        catch(error) {
            const errorCode = error.code;
            if(errorCode=='auth/missing-email'){
            alert('Please enter email address.');
            }
            else if(errorCode=='auth/invalid-email'){
            alert('Please enter valid email.');
            }
            else if(errorCode=='auth/missing-password'){
            alert('Please enter valid password.')
            }
            else if(errorCode=='auth/invalid-credential'){
            alert('Invalid credential. Please try again.')
            }
            else if(errorCode=='auth/user-not-found'){
            alert('User not found.');
            }
        };
        
    }

    // Login Form
	return(<>
        <Topbar page="LoginSignup"/>
        <Container>
            <Row className="wrapper-row">
                <Col xs={12} md={4} className='wrapper-col'>
                    <Form  className='login-form'>
                        <Form.Group className="mb-3" controlId="formBasicEmail" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Enter email" value={LoginEmail} onChange={(event)=>{setLoginEmail(event.target.value)}}/>
                        </Form.Group>
            
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={LoginPassword} onChange={(event)=>setLoginPassword(event.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3 login-links">
                            <Link to="/signup">Please Signup Here!</Link>
                            <Link to="">Forgot your password?</Link>
                        </Form.Group>
                        <Button type="button" onClick={handleLoginSubmit} className='login-button'>Log in</Button>
                        { (state!=null && state.sessionExpired===true)? 
                            <Form.Group className="mb-3 session-expired">
                                <Form.Label>Session expired. Please log in again. </Form.Label>
                            </Form.Group>:<></>
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    </>    
	)
}

export default Login;