import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyAFbl_RGx1IG-o4dtoBV8hT0ZulUBfxWw0',
    authDomain: 'socrate-156a7.firebaseapp.com',
    projectId: 'socrate-156a7',
    storageBucket: 'socrate-156a7.appspot.com',
    messagingSenderId: '366901244709',
    appId: '1:366901244709:web:79f2dd3a581d41d94b60cc',
    measurementId: 'G-S8679GJYCV',
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
