import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, user}) => {
  if(user==null && localStorage.getItem('token')!=null){
    user={'token':localStorage.token};
  }
  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;